<template>
    <DataDetails :Data="Data" :Groups="Groups" :Delta="Delta" :Account="Account" @deltachange="onDeltaChanged" />
</template>

<script>
import MetricService from "@/services/metrics";
import DataDetails from "@/components/common/DataDetails";

export default {
    name: "EciDetails",
    components: {
        DataDetails
    },
    data() {
        return {
            loading: true,
            Account:null,
            Delta: 1,
            Data: null,
            Groups:[],
            Lists:{},
        }
    },
    service: null,
    created() {
        this.service = new MetricService()
    },
    watch: {
        Delta:function() {
            this.loadData()
        }
    },
    mounted() {
        this.Account = this.$router.currentRoute.value.params.account;
        this.loadData()

    },
    methods: {
        onDeltaChanged: function(delta) {
            this.Delta = delta
        },
        loadData() {
            this.loading = true
            this.service.getOverviewEci(this.Account, this.Delta).then(data => {
                let groups = {};
                for (const [, value] of Object.entries(data)) {
                    const grp = value["Field"]["Group"];

                    if (grp in groups) {
                        groups[grp].push(value)

                    } else {
                        groups[grp] = [value]
                        this.Groups.push(grp)
                    }
                }
                this.Data = groups
                this.loading = false
            })

        }
    }
}
</script>
<style scoped>
h3 {
    margin: 0;
    padding-bottom: 10px;

}
.p-card {
    padding:5px;
    margin-bottom:10px;
    font-size:11pt;
}
</style>